import * as React from 'react';
import { ContinueLink } from '../../components/ContinueLink';
import { PageCard, Section } from '../../components/Section';
import { PageHeading } from '../../components/page-heading';
import { SectionHeading } from '../../components/section-heading';
import { Labels } from '../../messages';
import { pageMap } from '../../paths';
import { useActions } from '../../state';
import { CompanyInfoForm } from './CompanyInfoForm';
import { CompanyPurposeForm } from './CompanyPurposeForm';

const StepPage1: React.FC = () => {
  const { editorThunks } = useActions();
  const { submitEmail, trackFormOwnerInfo } = editorThunks;

  React.useEffect(() => {
    submitEmail();
  }, [submitEmail]);

  React.useEffect(() => {
    trackFormOwnerInfo();
  }, [trackFormOwnerInfo]);

  return (
    <>
      <PageCard>
        <Section>
          <PageHeading>Om selskapet</PageHeading>

          <Section>
            <SectionHeading>Hva skal selskapet hete?</SectionHeading>
            <CompanyInfoForm />
          </Section>

          <Section>
            <SectionHeading>Hva skal selskapet drive med?</SectionHeading>
            <CompanyPurposeForm />
          </Section>
        </Section>
      </PageCard>

      <ContinueLink to={pageMap.step2}>
        <Labels.Onward />
      </ContinueLink>
    </>
  );
};

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default StepPage1;
